import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Button } from 'components/Button/Button';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { StateWrapper } from 'docs/helpers/StateWrapper';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { LineLoader, ColoredLineLoader, StyledLineLoader, useLineLoader } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/LineLoader" title="LineLoader" mdxType="PageTitle" />
    <h2 {...{
      "id": "basic-line-loader"
    }}>{`Basic line loader`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <LineLoader />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      LineLoader,
      ColoredLineLoader,
      StyledLineLoader,
      useLineLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <LineLoader mdxType="LineLoader" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "top-loader"
    }}>{`Top loader`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <LineLoader position=\"top\" />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      LineLoader,
      ColoredLineLoader,
      StyledLineLoader,
      useLineLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <LineLoader position="top" mdxType="LineLoader" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "bottom-loader"
    }}>{`Bottom loader`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <LineLoader position=\"bottom\" />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      LineLoader,
      ColoredLineLoader,
      StyledLineLoader,
      useLineLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <LineLoader position="bottom" mdxType="LineLoader" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "fixed-loader"
    }}>{`Fixed loader`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <LineLoader fixed position=\"top\" />\n  (There should be a loader at the top of the page)\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      LineLoader,
      ColoredLineLoader,
      StyledLineLoader,
      useLineLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <LineLoader fixed position="top" mdxType="LineLoader" />
    (There should be a loader at the top of the page)
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "finite-loader"
    }}>{`Finite loader`}</h2>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <StateWrapper initialState={3}>\n    {({ state: loops, setState: setLoops }) => (\n      <React.Fragment>\n        <Looper\n          end={5}\n          render={i => (\n            <Rhythm key={i} m={1}>\n              <Button\n                color={i === loops ? \'primary\' : \'neutral\'}\n                onClick={() => setLoops(i)}\n              >{`${i} loops`}</Button>\n            </Rhythm>\n          )}\n          start={1}\n          step={1}\n        />\n        <LineLoader\n          key={loops}\n          loops={loops}\n          onFinish={() => console.log(\'finished\')}\n          onLoop={({ loop }) => console.log(`finite loop ${loop}`)}\n          position=\"top\"\n        />\n      </React.Fragment>\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      LineLoader,
      ColoredLineLoader,
      StyledLineLoader,
      useLineLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={3} mdxType="StateWrapper">
      {({
            state: loops,
            setState: setLoops
          }) => <React.Fragment>
          <Looper end={5} render={i => <Rhythm key={i} m={1} mdxType="Rhythm">
                <Button color={i === loops ? 'primary' : 'neutral'} onClick={() => setLoops(i)} mdxType="Button">{`${i} loops`}</Button>
              </Rhythm>} start={1} step={1} mdxType="Looper" />
          <LineLoader key={loops} loops={loops} onFinish={() => console.log('finished')} onLoop={({
              loop
            }) => console.log(`finite loop ${loop}`)} position="top" mdxType="LineLoader" />
        </React.Fragment>}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "stoppable-loader"
    }}>{`Stoppable loader`}</h2>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <StateWrapper>\n    {({ state: percent, setState: setPercent }) => (\n      <React.Fragment>\n        <Button\n          color=\"primary\"\n          onClick={() => setPercent(percent ? undefined : 50)}\n        >\n          {percent ? \'Restart\' : \'Stop\'}\n        </Button>\n        <LineLoader\n          onLoop={({ loop }) => console.log(`stoppable loop ${loop}`)}\n          percent={percent}\n          position=\"bottom\"\n        />\n      </React.Fragment>\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      LineLoader,
      ColoredLineLoader,
      StyledLineLoader,
      useLineLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper mdxType="StateWrapper">
      {({
            state: percent,
            setState: setPercent
          }) => <React.Fragment>
          <Button color="primary" onClick={() => setPercent(percent ? undefined : 50)} mdxType="Button">
            {percent ? 'Restart' : 'Stop'}
          </Button>
          <LineLoader onLoop={({
              loop
            }) => console.log(`stoppable loop ${loop}`)} percent={percent} position="bottom" mdxType="LineLoader" />
        </React.Fragment>}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <h3 {...{
      "id": "contrast-loader"
    }}>{`Contrast loader`}</h3>
    <Playground __position={7} __code={'<ThemeWrapper contrast>\n  <LineLoader contrast />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      LineLoader,
      ColoredLineLoader,
      StyledLineLoader,
      useLineLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <LineLoader contrast mdxType="LineLoader" />
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "colored-line-loader"
    }}>{`Colored line loader`}</h3>
    <Playground __position={8} __code={'<ColoredLineLoader colorId=\"P20\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      LineLoader,
      ColoredLineLoader,
      StyledLineLoader,
      useLineLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ColoredLineLoader colorId="P20" mdxType="ColoredLineLoader" />
    </Playground>
    <h3 {...{
      "id": "styled-line-loader"
    }}>{`Styled line loader`}</h3>
    <Playground __position={9} __code={'<StyledLineLoader color=\"#556270\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      LineLoader,
      ColoredLineLoader,
      StyledLineLoader,
      useLineLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StyledLineLoader color="#556270" mdxType="StyledLineLoader" />
    </Playground>
    <h2 {...{
      "id": "uselineloader-hook"
    }}>{`useLineLoader hook`}</h2>
    <Playground __position={10} __code={'() => {\n  const { loader, onLoad, loaderFinishing } = useLineLoader({\n    position: \'top\',\n  })\n  return (\n    <ThemeWrapper>\n      <StateWrapper initialState={false}>\n        {({ state: loaded, setState: setLoaded }) => (\n          <React.Fragment>\n            {(!loaded || loaderFinishing) && loader}\n            {!loaded && (\n              <Button\n                color=\"primary\"\n                onClick={() => {\n                  setLoaded(true)\n                  onLoad()\n                }}\n              >\n                Trigger\n              </Button>\n            )}\n            {loaded && \'Loaded!\'}\n          </React.Fragment>\n        )}\n      </StateWrapper>\n    </ThemeWrapper>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      LineLoader,
      ColoredLineLoader,
      StyledLineLoader,
      useLineLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const {
          loader,
          onLoad,
          loaderFinishing
        } = useLineLoader({
          position: 'top'
        });
        return <ThemeWrapper mdxType="ThemeWrapper">
        <StateWrapper initialState={false} mdxType="StateWrapper">
          {({
              state: loaded,
              setState: setLoaded
            }) => <React.Fragment>
              {(!loaded || loaderFinishing) && loader}
              {!loaded && <Button color="primary" onClick={() => {
                setLoaded(true);
                onLoad();
              }} mdxType="Button">
                  Trigger
                </Button>}
              {loaded && 'Loaded!'}
            </React.Fragment>}
        </StateWrapper>
      </ThemeWrapper>;
      }}
    </Playground>
    <div style={{
      "height": "50px"
    }} />
    <Playground __position={12} __code={'() => {\n  const { loader, onLoad, loaderFinishing } = useLineLoader({\n    position: \'top\',\n    component: ColoredLineLoader,\n    colorId: \'P20\',\n  })\n  return (\n    <ThemeWrapper>\n      <StateWrapper initialState={false}>\n        {({ state: loaded, setState: setLoaded }) => (\n          <React.Fragment>\n            {(!loaded || loaderFinishing) && loader}\n            {!loaded && (\n              <Button\n                color=\"primary\"\n                onClick={() => {\n                  setLoaded(true)\n                  onLoad()\n                }}\n              >\n                Trigger\n              </Button>\n            )}\n            {loaded && \'Loaded!\'}\n          </React.Fragment>\n        )}\n      </StateWrapper>\n    </ThemeWrapper>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      LineLoader,
      ColoredLineLoader,
      StyledLineLoader,
      useLineLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const {
          loader,
          onLoad,
          loaderFinishing
        } = useLineLoader({
          position: 'top',
          component: ColoredLineLoader,
          colorId: 'P20'
        });
        return <ThemeWrapper mdxType="ThemeWrapper">
        <StateWrapper initialState={false} mdxType="StateWrapper">
          {({
              state: loaded,
              setState: setLoaded
            }) => <React.Fragment>
              {(!loaded || loaderFinishing) && loader}
              {!loaded && <Button color="primary" onClick={() => {
                setLoaded(true);
                onLoad();
              }} mdxType="Button">
                  Trigger
                </Button>}
              {loaded && 'Loaded!'}
            </React.Fragment>}
        </StateWrapper>
      </ThemeWrapper>;
      }}
    </Playground>
    <div style={{
      "height": "50px"
    }} />
    <Playground __position={14} __code={'() => {\n  const { loader, onLoad, loaderFinishing } = useLineLoader({\n    position: \'top\',\n    component: StyledLineLoader,\n    color: \'#556270\',\n  })\n  return (\n    <ThemeWrapper>\n      <StateWrapper initialState={false}>\n        {({ state: loaded, setState: setLoaded }) => (\n          <React.Fragment>\n            {(!loaded || loaderFinishing) && loader}\n            {!loaded && (\n              <Button\n                color=\"primary\"\n                onClick={() => {\n                  setLoaded(true)\n                  onLoad()\n                }}\n              >\n                Trigger\n              </Button>\n            )}\n            {loaded && \'Loaded!\'}\n          </React.Fragment>\n        )}\n      </StateWrapper>\n    </ThemeWrapper>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Looper,
      Rhythm,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      LineLoader,
      ColoredLineLoader,
      StyledLineLoader,
      useLineLoader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const {
          loader,
          onLoad,
          loaderFinishing
        } = useLineLoader({
          position: 'top',
          component: StyledLineLoader,
          color: '#556270'
        });
        return <ThemeWrapper mdxType="ThemeWrapper">
        <StateWrapper initialState={false} mdxType="StateWrapper">
          {({
              state: loaded,
              setState: setLoaded
            }) => <React.Fragment>
              {(!loaded || loaderFinishing) && loader}
              {!loaded && <Button color="primary" onClick={() => {
                setLoaded(true);
                onLoad();
              }} mdxType="Button">
                  Trigger
                </Button>}
              {loaded && 'Loaded!'}
            </React.Fragment>}
        </StateWrapper>
      </ThemeWrapper>;
      }}
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "lineloader"
    }}>{`LineLoader`}</h3>
    <Props of={LineLoader} mdxType="Props" />
    <h3 {...{
      "id": "coloredlineloader"
    }}>{`ColoredLineLoader`}</h3>
    <Props of={ColoredLineLoader} mdxType="Props" />
    <h3 {...{
      "id": "styledlineloader"
    }}>{`StyledLineLoader`}</h3>
    <Props of={StyledLineLoader} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      